.nav-item {
    position: relative;

    transition: var(--transition-duration);

    cursor: pointer;
    user-select: none;
}

.nav-item:hover,
.nav-item.click {
    background-color: var(--sand-light);
}

.nav-item-text {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;

    font-size: 18px;
    text-align: center;
    text-decoration: none;
    color: var(--sand-light);
}

.nav-item:hover .nav-item-text,
.nav-item.click .nav-item-text {
    color: var(--green);
}

.nav-item-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    padding: 0 4px;

    color: var(--sand-light);

    transform: rotate(180deg);
    transition: var(--transition-duration);
}

.nav-item-arrow.rotate {
    transform: rotate(360deg);
}

.nav-item:hover .nav-item-arrow,
.nav-item.click .nav-item-arrow {
    color: var(--green);
}

.nav-item-menu {
    position: absolute;

    border: solid var(--green-light);
    border-width: 0 2px 2px 2px;

    border-radius: 0 0 10px 10px;
    background-color: var(--sand-light);

    overflow: hidden;

    transform: translateX(-2px);
    z-index: 100;
}

.nav-item-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 0 14px;

    text-decoration: none;
    white-space: nowrap;
    color: var(--green);
}

.nav-item-menu-item:hover {
    color: var(--sand-light);

    background-color: var(--green);
}