.import-data {
    padding: 60px;
}

.import-data > .manual-text {
    display: inline-block;
    max-width: 800px;
    margin-bottom: 5px;

    font-size: 20px;
    font-weight: 500;
    color: var(--green-light);
}

.import-data > .b-options {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 20px 0 0 0;
}

.import-data > .b-options > select {
    width: 150px;
    height: 40px;
    padding: 0 10px;
    border: 2px solid var(--green);

    font-size: 18px;
    color: var(--green-light);

    outline: none;
    cursor: pointer;
}

.import-data > .b-options .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border: 3px solid var(--green-light);

    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;

    border-radius: 20px;
    background-color: var(--green);

    transition: 0.4s;

    user-select: none;
    cursor: pointer;
}

.import-data > .b-options .button:hover {
    background-color: var(--green-dark);
}

.import-data > .b-options .button.hidden {
    display: none;
}

.import-data > .b-options input[type="file"] {
    display: none;
}

.import-data > .b-options > label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.import-data > .b-options span {
    font-size: 18px;
    color: var(--green-light);
}

.import-data .download-status {
    margin-left: -20px;
}