.login {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 60px;

    background-color: var(--sand-light);
}

.login > .login-wd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;

    border-radius: 30px;
    background-color: var(--sand);
    box-shadow: 0 0 2px gray;
}

.login label {
    position: relative;

    width: fit-content;
    width: 260px;
    height: 40px;
    margin-bottom: 15px;
}

.login input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border: 1px solid gray;

    font-size: 17px;

    border-radius: 10px;
    outline: none;
}

.login input:last-of-type {
    margin-bottom: 25px;
}

.login label > .hide-pass {
    position: absolute;
    right: 10px;
    top: 50%;

    width: 23px;
    height: 23px;

    transform: translateY(-50%);

    cursor: pointer;
}

.login label > .hide-pass > svg {
    width: 100%;
    height: 100%;
}

.login .login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 30px;
    border: 3px solid var(--green-light);

    font-weight: 500;
    text-transform: uppercase;
    color: white;

    border-radius: 12px;
    background-color: var(--green);

    transition: 0.4s;

    user-select: none;
    cursor: pointer;
}

.login .login-btn:hover {
    background-color: var(--green-dark);
}

.login .err {
    margin-top: 15px;
    color: red;
}