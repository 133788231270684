.sound {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19.5px;
    height: 19.5px;

    font-size: 20px;
    color: var(--sand-light);

    border-radius: 6px;
    background-color: var(--green);

    cursor: pointer;

    --trans-icon: 1px;
}

.sound:hover {
    background: var(--green-light);
}

.sound svg {
    transform: translateX(var(--trans-icon));
}