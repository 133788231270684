.table-wraper {
    min-width: 100%;
    padding: 28px 33px;
}

.table-wraper table {
    position: relative;

    min-width: 100%;

    border-collapse: collapse;
}

.table-wraper table::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;

    width: 100%;
    height: 100%;
    border: 1px solid var(--green-light);
    box-sizing: border-box;

    border-radius: 12px;

    pointer-events: none;
}

.table-wraper table::after {
    content: "";

    position: absolute;
    top: -4px;
    left: -4px;
    z-index: 10;

    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border: 5px solid var(--green);
    box-sizing: border-box;

    border-radius: 14px;

    pointer-events: none;
}

.table-wraper td {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}

.table-wraper .td-child {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4px 5px;
    border: solid var(--green-light);
    border-width: 1px 1px 0 0;

    white-space: nowrap;
}

.table-wraper .td-child span {
    flex-grow: 1;
}

/* Border fix */

.table-wraper  tr td:first-of-type .td-child {
    border-left-width: 1px;
}

.table-wraper  tbody tr:last-of-type .td-child {
    border-bottom-width: 1px;
}