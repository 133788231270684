.settings-bar {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    max-width: 100%;

    color: var(--green);
}

.settings-bar-text {
    height: 100%;
    margin-right: 6px;

    font-size: 18px;
    line-height: 25px;
}

.settings-bar-cell {
    display: flex;
    align-items: center;
    margin: 0 16px 0 0;
}

.settings-bar-cell > .name {
    display: inline-block;
    margin-right: 7px;

    font-size: 18px;
}

.settings-bar-cell > .switch {
    display: inline-block;
}

.settings-bar-cell > .switch > input {
    display: none;
}

.settings-bar-cell > .switch > .slider {
    display: flex;
    align-items: center;
    width: 54px;
    height: 25px;
    padding: 0 4px;

    border-radius: 12px;
    background-color: var(--green);
    box-shadow: 0 0 1px #434343;

    transition: 0.4s;
    transition-property: background-color, box-shadow;

    cursor: pointer;
}

.settings-bar-cell > .switch > .slider::before {
    content: "";

    display: block;
    height: 20px;
    aspect-ratio: 1/1;

    background-color: var(--sand-light);
    border-radius: 50%;

    transition: transform 0.4s;
}

.settings-bar-cell > .switch > input:checked + .slider {
    background-color: var(--green-light);
}

.settings-bar-cell > .switch > input:checked + .slider::before {
    transform: translateX(26px);
}