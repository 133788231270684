.table-settings-top {
    position: sticky;
    left: 0;

    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: 100%;
    gap: 40px;
    width: 100vw;
    height: 62px;
    padding: 0 33px 0 80px;

    background-color: var(--sand-light);
}

.table-settings-bottom {
    position: sticky;
    left: 0;

    display: flex;
    justify-content: flex-end;
    width: 100vw;
    padding: 0 33px;
    padding-bottom: 70px;
}

.table-setting {
    display: flex;
    align-items: center;
}

.table-setting > .download-csv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    border: 3px solid var(--green-light);

    font-weight: 500;
    text-decoration: none;
    color: white;

    border-radius: 12px;
    background-color: var(--green);

    transition: 0.4s;

    user-select: none;
    cursor: pointer;
}

.table-setting > .download-csv:hover {
    background-color: var(--green-dark);
}