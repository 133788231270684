.sec-edit-speakers {
    padding: 60px;
}

.b-edit-speakers {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 700px;
}

.b-speaker {
    display: grid;
    grid-template-rows: 60px 1fr 50px;
    grid-auto-columns: 100%;
    width: 100%;
    height: 300px;

    border-radius: 20px;
    box-shadow: 0 0 2px gray;
    background-color: var(--sand);

    overflow: hidden;
}

.b-speaker-header {
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 30px;

    background-color: var(--sand-dark);
    box-shadow: 0 0 1.5px gray;
}

.b-speaker-header > span {
    font-size: 21px;
    color: var(--green-light);
}

.b-speaker-header > .dialect > input {
    width: 200px;
}

.b-speaker-header > span > input {
    position: relative;
    top: -1px;

    width: 40px;
    height: 26px;

    font-size: 16px;
    text-align: center;
}

.b-speaker-header > span > input.disabled {
    border: none;

    font-size: 21px;
    color: white;
    text-shadow: 0 0 1px black;

    background-color: transparent;
}

.b-speaker-desc {
    width: 100%;
    height: 100%;
}

.b-speaker-desc > textarea {
    width: 100%;
    height: 100%;
    padding: 13px 10px;
    border: none;

    font-size: 17px;

    resize: none;
    outline: none;
}

.b-speaker-footer {
    z-index: 1;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    width: 100%;
    height: 100%;
    padding: 0 20px;

    background-color: var(--sand-dark);
    box-shadow: 0 0 1.5px gray;
}

.b-speaker-footer > .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border: 3px solid var(--green-light);

    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;

    border-radius: 20px;
    background-color: var(--green);

    transition: 0.4s;

    user-select: none;
    cursor: pointer;
}

.b-speaker-footer > .button:hover {
    background-color: var(--green-dark);
}

.b-add-speaker {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 3px solid var(--green);

    border-radius: 50%;
    background-color: white;

    user-select: none;
    cursor: pointer;
}

.b-add-speaker > svg {
    width: 70%;
    height: 70%;

    color: var(--green);
}