@font-face {
    font-family: "consolas";
    src: url("/resource/fonts/other/consolas.ttf");
}

@font-face {
    font-family: "Georama";
    font-style: normal;
    font-weight: normal;
    src: url("/resource/fonts/georama/Georama-Regular.ttf");
}

@font-face {
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    src: url("/resource/fonts/georama/Georama-Medium.ttf");
}

@font-face {
    font-family: "Georama";
    font-style: normal;
    font-weight: bold;
    src: url("/resource/fonts/georama/Georama-Bold.ttf");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    src: url("/resource/fonts/inter/Inter-Regular.ttf");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: url("/resource/fonts/inter/Inter-Medium.ttf");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    src: url("/resource/fonts/inter/Inter-Bold.ttf");
}

@font-face {
    font-family: "Playfair";
    font-style: normal;
    font-weight: normal;
    src: url(/resource/fonts/playfair/Playfair-Regular.ttf);
}

@font-face {
    font-family: "Playfair";
    font-style: normal;
    font-weight: 500;
    src: url(/resource/fonts/playfair/Playfair-Medium.ttf);
}

@font-face {
    font-family: "Playfair";
    font-style: normal;
    font-weight: bold;
    src: url(/resource/fonts/playfair/Playfair-Bold.ttf);
}

/* new root */
:root {
    --green: #969265;
    --green-light: #808008;
    --green-dark: #b3ae7a;

    --sand: #F8EED6;
    --sand-dark: #F0DFB4;
    --sand-light: #FCFAEE;

    --transition-duration: 0.5s;
}

* {
    margin: 0;
    box-sizing: border-box;

    font-family: Inter;
}

html {
    overflow: overlay;
}

body {
    width: fit-content;
    min-width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100vh;
}

.not-supported {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 30px;

    font-size: 50px;
    font-weight: 500;
    text-align: center;
    color: var(--green-light);

    background-color: var(--sand-light);
}

/* State styles */

.hidden {
    display: none !important;
}

.disabled {
    color: var(--sand-light) !important;

    pointer-events: none !important;
}