footer {
    position: sticky;
    left: 0;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 140px;
    padding: 0 15px;

    background-color: var(--sand);
    box-shadow: 0 0 15px #000000;
}

.logo-wraper {
    display: flex;
    justify-content: center;
    width: 320px;
    height: 93px;
}

.logo-wraper a {
    display: block;
    width: fit-content;
    height: 100%;
}

.logo-wraper img {
    height: 100%;
}

.text-wraper {
    flex-grow: 1;

    font-size: 20px;
    text-align: center;
    color: #000000;
}