.text-page {
    flex-grow: 1;
    width: 100vw;
    min-height: 100%;

    background-color: var(--sand-light);
}

.text-page-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;

    background-color: var(--sand-dark);

    overflow: hidden;
}

.text-page-header > span {
    font-family: Georama;
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 10px;
    text-shadow: -8px 6px 13px #00000080;

    color: var(--sand-light);

    user-select: none;
}

.text-page-content {
    width: 100%;
    padding: 30px 250px 70px;
}

.text-page-content > .paragraph {
    width: 100%;
}

.text-page-content > .paragraph + .paragraph {
    margin-top: 60px;
}

.text-page-content > .paragraph > h1 {
    margin-bottom: 20px;

    font-family: Georama;
    font-size: 32px;
    color: var(--green-light);
}

.text-page-content > .paragraph > p {
    width: 100%;

    font-size: 24px;
    white-space: pre-line;
    color: var(--green);
}

.text-page-content > .paragraph > p > span {
    display: inline-block;
    width: 100%;

    text-indent: 40px;
}

.text-page-content > .paragraph > p > span + span {
    margin-top: 12px;
}

.text-page-content > .sub-paragraph {
    width: 100%;
    padding-left: 60px;
}

.text-page-content > .paragraph + .sub-paragraph {
    margin-top: 20px;
}

.text-page-content > .sub-paragraph + .paragraph {
    margin-top: 60px;
}

.text-page-content > .sub-paragraph + .sub-paragraph {
    margin-top: 30px;
}

.text-page-content > .sub-paragraph > h1 {
    margin-bottom: 20px;

    font-family: Georama;
    font-size: 26px;
    color: var(--green-light);
}

.text-page-content > .sub-paragraph > p {
    width: 100%;

    font-size: 22px;
    white-space: pre-line;
    color: var(--green);
}

.text-page-content > .sub-paragraph > p > span {
    display: inline-block;
    width: 100%;

    text-indent: 34px;
}

.text-page-content > .sub-paragraph > p > span + span {
    margin-top: 10px;
}

.no-indent {
    font-weight: 500 !important;
    text-indent: 0 !important;
}

.text-page-content > table {
    border-spacing: 10px;
    width: 100%;
    margin: 30px 0;
}

.text-page-content > table td {
    padding: 8px;

    color: var(--green-light);

    background-color: var(--sand-dark);
}

.text-page-content > table thead td {
    font-size: 22px;
    font-weight: bold;
}

.text-page-content > table tbody td {
    font-size: 18px;
    font-weight: bold;
}

.text-page-content > table tbody td.thin {
    font-size: 16px;
    font-weight: normal;
}
