.pages-bar {
    display: flex;
    gap: 5px;
    width: fit-content;
    padding: 6px;

    font-size: 14px;

    border-radius: 10px;
    background-color: var(--sand-light);
}

.pages-bar-block {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 23px;
    height: 23px;
    padding: 5px;

    border-radius: 8px;
    background-color: var(--sand-dark);
    text-decoration: none;
    
    color: var(--green-light);

    user-select: none;
    cursor: pointer;
}

.pages-bar-prev svg {
    transform: rotate(-90deg) translateY(-1.5px);
}

.pages-bar-next svg {
    transform: rotate(90deg) translateY(-1.5px);
}

.pages-bar-gap {
    cursor: default;
}