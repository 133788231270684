.p-admin {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 400px 1fr;
    width: 100%;
    height: 100vh;
}

.p-admin > nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
    border-right: 5px solid var(--sand-dark);

    background-color: var(--sand);
}

.p-admin > nav > .item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;

    text-decoration: none;
}

.p-admin > nav > .item > span {
    position: relative;

    display: inline-block;

    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-decoration: none;

    color: var(--green-light);
}

.p-admin > nav > .item > span::after {
    content: "";

    position: absolute;
    bottom: -1px;
    left: 0;

    width: 100%;
    height: 2.5px;

    background-color: var(--green-light);
    transform: scale3d(0, 1, 1);
    transform-origin: 100% 50%;

    transition: 0.4s transform;
}

.p-admin > nav > .item.active > span::after {
    transform: scale3d(1, 1, 1);
    transform-origin: 0 50%;
}

.p-admin > section {
    width: 100%;
    height: 100%;

    background-color: var(--sand-light);
    
    overflow-y: auto;
}

.p-admin > section > * {
    width: 100%;
    min-height: 100%;
}

/* update row */

.p-admin > section > .sec-edit-row {

}

/* import data */

.p-admin > section > .sec-import-date {

}

.dev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    font-size: 60px;
    font-weight: 500;
    color: var(--green);
    text-transform: uppercase;
}