.upload-audio {
    width: 100%;
    height: 100%;
    padding: 80px;
}

.upload-audio > .b-drag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    height: 100%;
    border: 5px dashed var(--green-light);
    
    border-radius: 30px;
}

.upload-audio > .b-drag.over {
    border: 5px solid var(--green-light);
}

.upload-audio > .b-drag > span {
    display: inline-block;
    width: 100%;

    font-size: 40px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: var(--green-light);

    user-select: none;
}

.upload-audio > .b-drag .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 80px;
    border: 3px solid var(--green-light);

    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;

    border-radius: 35px;
    background-color: var(--green);

    transition: 0.4s;

    user-select: none;
    cursor: pointer;
}

.upload-audio > .b-drag .button:hover {
    background-color: var(--green-dark);
}

.upload-audio > .b-drag .button.disabled {
    filter: grayscale(0.5);

    pointer-events: none;
}