main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: max-content;
    min-width: 100%;
}

.general-page {
    flex-grow: 1;
    width: 100%;
    min-height: 100%;

    background-color: var(--sand-light);
}

.table-module {
    flex-grow: 1;
    width: fit-content;
    min-width: 100%;

    background-color: var(--green);
}

.error-page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100%;
    padding: 60px;

    background-color: var(--sand-light);
}