.team-page {
    flex-grow: 1;
    width: 100vw;
    min-height: 100%;

    background-color: var(--sand-light);
}

.team-page-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;

    background-color: var(--sand-dark);

    overflow: hidden;
}

.team-page-header > span {
    font-family: Georama;
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 10px;
    text-shadow: -8px 6px 13px #00000080;

    color: var(--sand-light);

    user-select: none;
}

.team-page-content {
    width: 100%;
    padding: 30px 100px;
}

.profile-wraper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 120px;
    row-gap: 40px;
    width: 100%;
    padding: 0 100px;
    margin-bottom: 25px;
}

.profile {
    display: block;
    width: 240px;
}

.profile > span {
    display: inline-block;
    width: 100%;
}

.profile > img {
    width: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 14px;

    border-radius: 10px;
}

.profile > .name {
    margin-bottom: 6px;

    font-family: Georama;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    color: var(--green-light);
}

.profile > .desc {
    padding: 10px;

    font-family: Georama;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
    color: #969265;
}

.team-description {
    width: 800px;
    margin: auto;
}

.team-description > h1 {
    margin-bottom: 20px;

    font-family: Georama;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    color: var(--green-light);
}

.team-description > p {
    font-family: Georama;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    color: var(--green-light);
}

.team-description a {
    color: var(--green-light);
}

.team-description a:hover {
    color: var(--green);
}