thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

thead .td-child {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    color: var(--green-light);

    background-color: var(--sand-dark);
}

thead .td-child {
    background-color: var(--sand-dark);
}

thead tr:last-of-type .td-child {
    border: solid var(--green-light);
    border-width: 1px 1px 1px 0;
}

.sortButt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    aspect-ratio: 1/1;
    margin-left: 4px;

    color: var(--sand-light);

    border-radius: 9px;
    background-color: var(--green-light);
    cursor: pointer;
}