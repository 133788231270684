main > nav {
    position: sticky;
    left: 0;
    z-index: 100;

    display: flex;
    width: 100vw;
    height: 45px;
    padding: 0 44px;
    border-bottom: 2px solid var(--green-light);

    background-color: var(--green);
}