.dev-text {
    display: inline-block;
    width: 100%;
    margin-top: -2em;

    font-size: 60px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--green-light)
}