tbody .td-child {
    background-color: white;
}

tbody tr:first-of-type .td-child {
    border: solid var(--green-light);
    border-width: 0 1px 0 0;
}

tbody .td-child span {
    padding-right: 5px;
}