.error-type {
    font-size: 90px;
}

.error-text {
    font-size: 60px;
}

.error-type,
.error-text {
    display: inline-block;
    width: 100%;

    text-align: center;
    font-weight: 500;
    color: var(--green-light)
}