header {
    position: sticky;
    left: 0;
    z-index: 101;

    width: 100vw;
    height: 110px;
    padding: 0 170px 10px;

    box-shadow: 0 0 5px #000000;
    background-image: url(/resource/images/flower.png),
                      url(/resource/images/lion.png),
                      url(/resource/images/header-bg.png);
    background-size: auto 100%,
                     auto 100%,
                     cover;
    background-position: left top,
                         right bottom;
    background-repeat: no-repeat;

    user-select: none;
}

header > a {
    display: flex; 
    align-items: center;
    width: fit-content;
    height: 100%;

    font-family: "Playfair";
    font-size: 39px;
    font-weight: bold;
    line-height: 45px;
    letter-spacing: 1px;
    white-space: break-spaces;
    text-decoration: none;
    color: white;
}