.general-page {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 60% 40%;
    width: 100vw;
}

.general-page > .text {
    flex-grow: 1;
    width: 100%;
    padding: 60px;
}

.general-page > .text > .main-text {
    font-size: 18px;
    font-weight: 500;
    word-break: break-word;
    color: var(--green-light)
}

.general-page > .text > .main-text > ul {
    margin-top: 6px;
}

.paragraph1 {
    width: 100%;
    margin-bottom: 1em;
}

.paragraph2 {
    width: 70%;
    max-width: 650px;
    margin-bottom: 1em;
}

.general-page > .b-cite {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 30px;
}

.general-page .cite {
    width: 65%;
    padding: 30px;

    border-radius: 10px;
    background-color: var(--sand);
    box-shadow: 0 0 2px gray;
}

.general-page .cite-text {
    font-size: 16px;
}

.general-page .cite-text > .smaller {
    font-size: 1em;
}

.general-page a {
    color: var(--green-dark);
}

.general-page a:hover {
    color: var(--green);
}