.edit-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 100px;
}

.edit-row > * {
    flex-shrink: 0;
}

.edit-row > .b-search {
    display: flex;
    width: 900px;
    height: 70px;
    border: 3px solid var(--green-light);

    border-radius: 9999px;
    background-color: var(--sand);
}

.edit-row > .b-search > input {
    flex-grow: 1;
    height: 100%;
    padding: 0 25px;
    border: none;

    font-size: 28px;

    border-radius: 9999px 0 0 9999px;

    outline: none;
}

.edit-row > .b-search > .b-search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    border: solid var(--green-light);
    border-width: 0 0 0 2px;

    cursor: pointer
}

.edit-row > .b-search > .b-search-btn > svg {
    width: 32px;
    height: 32px;

    color: var(--green-light);
}

.edit-row > .b-error {
    display: inline-block;
    margin-top: 20px;

    font-size: 20px;
    color: red;
}



.edit-row > .tbl-wraper {
    width: 100%;
    padding-bottom: 17px;
    margin-top: 50px;

    overflow-x: auto;
}

.edit-row > .tbl-wraper > table {
    width: 100%;

    border-radius: 12px;
    border-spacing: 0;
}

.edit-row > .tbl-wraper > table > * {
    width: 100%;
}

.edit-row > .tbl-wraper > table td {
    padding: 5px 10px;
    border: solid var(--green-light);
    border-width: 1px 1px 0 0;

    font-size: 17px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    color: var(--green-light);

    overflow: hidden;
}

.edit-row > .tbl-wraper > table > tbody td {
    height: 30px;
    padding: 0;
}

.edit-row > .tbl-wraper > table > thead {
    background-color: var(--sand-dark);
}

.edit-row > .tbl-wraper > table tbody input {
    min-width: 100%;
    height: 100%;
    padding: 0 5px;
    border: none;

    font-size: 16px;

    outline: none;
}

/* border fix */

.edit-row > .tbl-wraper > table tr td:first-of-type {
    border-left-width: 1px;
}

.edit-row > .tbl-wraper > table tbody tr:last-of-type td {
    border-bottom-width: 1px;
}



.edit-row > .tbl-wraper > table > thead > tr:first-of-type td:first-of-type {
    border-radius: 12px 0 0 0;
}

.edit-row > .tbl-wraper > table > thead > tr:first-of-type td:last-of-type {
    border-radius: 0 12px 0 0;
}

.edit-row > .tbl-wraper > table > tbody td:last-of-type {
    border-radius: 0 0 12px 0;
}

.edit-row > .tbl-wraper > table > tbody td:first-of-type {
    border-radius: 0 0 0 12px;
}


.edit-row > .b-btns {
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

.edit-row > .b-btns > .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border: 3px solid var(--green-light);

    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFFFFF;

    border-radius: 20px;
    background-color: var(--green);
    
    transition: 0.4s;

    user-select: none;
    cursor: pointer;
}

.edit-row > .b-btns > .btn:hover {
    background-color: var(--green-dark);
}